<style lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .nav-1 {
    font-size: 16px;
    font-weight: normal;
    color: #7f7f7f;
  }

  .nav-icon {
    margin: 0 5px;
    width: 16px;
    height: 16px;
    background: url("~@assets/images/orderlist/logoicon.png") center/cover
      no-repeat;
  }

  .nav-2 {
    font-size: 16px;
    color: #222c37;
    font-weight: bold;
  }
}

.form {
  margin-top: 20px;

  ::v-deep {
    .el-form-item {
      margin-bottom: 20px;
    }
    .el-form-item__error {
      margin-top: 10px;
      position: relative;
      top: 0;
    }
  }
}

.iphone {
  display: flex;
  width: 100%;

  ::v-deep .el-form-item {
    flex: 1;
    margin-bottom: 0px;
  }

  ::v-deep .m-1 {
    width: 100%;
    margin-right: 0px;
  }

  ::v-deep .m-2 {
    width: 170px;
    margin-right: 10px;
  }

  ::v-deep .m-3 {
    flex: 1;
    margin-right: 10px;
    &:last-of-type {
      margin: 0;
    }
  }
}

.bt-box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-left: 120px;

  .checkbox {
    ::v-deep .el-checkbox__input {
      border-radius: 50%;
      overflow: hidden;

      .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }

  .bt {
    width: 92px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #b1b1b1;
    box-sizing: border-box;
    line-height: 40px;
    color: #7f7f7f;
    text-align: center;

    &.save {
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #ffffff;

      margin-left: 10px;
      border: 0;
      background: #00c4a1;
    }
  }
}
</style>
<template>
  <div>
    <div class="nav">
      <div class="nav-1" @click="cancel">管理地址簿</div>
      <div class="nav-icon"></div>
      <div class="nav-2">地址详情</div>
    </div>
    <el-form
      class="form"
      :model="state.form"
      ref="ruleFormRef"
      label-position="left"
      require-asterisk-position="right"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="收货人" prop="name">
        <el-input
          name="name"
          placeholder="填写收货人姓名"
          v-model="state.form.name"
        />
      </el-form-item>
      <el-form-item label="手机号码" required>
        <div class="iphone">
          <el-select
            v-model="state.form.country_code"
            class="m-2"
            placeholder="中国大陆 +86"
            @change="
              changeA(
                state.form.country_code,
                countryCode.codeList,
                'addressCountryCode',
                true
              )
            "
          >
            <el-option
              v-for="item in countryCode.codeList"
              :key="item.id"
              :label="item.name + ' +' + item.id"
              :value="item.id"
            >
              {{ item.name }} + {{ item.id }}
            </el-option>
          </el-select>
          <el-form-item prop="mobile">
            <el-input
              name="mobile"
              placeholder="填写手机号"
              v-model="state.form.mobile"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <!-- 根据国家编码请求到省市区才显示所在地区及街道 -->
      <el-form-item v-if="state.province_enabled" label="所在地区" required>
        <div class="iphone">
          <el-select
            v-model="state.form.province_id"
            class="m-3"
            placeholder="省"
            @change="
              changeA(
                state.form.province_id,
                state.addressData,
                'addressCityData',
                true
              )
            "
          >
            <el-option
              v-for="item in state.addressData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
          <el-select
            v-model="state.form.city_id"
            @change="
              changeA(
                state.form.city_id,
                state.addressCityData,
                'addressCountyData',
                true
              )
            "
            :disabled="!state.form.province_id"
            class="m-3"
            placeholder="市"
          >
            <el-option
              v-for="item in state.addressCityData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
          <el-select
            v-model="state.form.area_id"
            class="m-3"
            placeholder="区"
            :disabled="!state.form.city_id"
            @change="
              changeA(
                state.form.area_id,
                state.addressCountyData,
                'addressStreetData',
                true
              )
            "
          >
            <el-option
              v-for="item in state.addressCountyData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item
        v-if="state.province_enabled && state.columnsStreet.length"
        label="选择街道"
      >
        <div class="iphone">
          <el-select
            v-model="state.form.street"
            class="m-1"
            placeholder="选择街道"
            :disabled="!state.form.area_id"
            @change="
              changeA(
                state.form.street,
                state.columnsStreet,
                'addressDetailData',
                true
              )
            "
          >
            <el-option
              v-for="item in state.columnsStreet"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="详细地址" prop="detail">
        <el-input
          name="detail"
          type="textarea"
          autosize
          resize="none"
          placeholder="填写详细地址和门牌号"
          v-model="state.form.detail"
        />
      </el-form-item>
      <el-form-item label="邮政编码">
        <el-input
          name="postal_code"
          placeholder="填写邮政编码"
          v-model="state.form.postal_code"
        />
      </el-form-item>
    </el-form>
    <div class="bt-box">
      <el-checkbox
        class="checkbox"
        v-model="state.form.is_default"
        :label="state.form.is_default ? '默认地址' : '设置为默认收货地址'"
        :true-label="1"
        text-color="#00C4A1"
        fill="#00C4A1"
        size="large"
      />
      <div style="display: flex">
        <el-button type="" @click="cancel">取消</el-button>
        <el-button
          type="primary"
          :disabled="save_btn_enabled == false"
          @click="addAddress"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  // eslint-disable-next-line no-unused-vars
  ref,
  // eslint-disable-next-line no-unused-vars
  watch,
  onMounted,
  reactive,
  // eslint-disable-next-line no-unused-vars
  defineExpose,
  // eslint-disable-next-line no-unused-vars
  computed,
  onBeforeUnmount,
} from "vue";
// eslint-disable-next-line no-unused-vars
import { get, post } from "@/utils/apiBase.js";
// import CompHeader from "@@/common/header.vue";
// eslint-disable-next-line no-unused-vars
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const ruleFormRef = ref({});
// eslint-disable-next-line no-unused-vars
const router = useRouter();
const route = useRoute();
const rules = {
  name: [
    {
      required: true,
      message: "收货人姓名长度需要在2-16个汉字或字符之间，不能包含特殊字符",
      trigger: "blur",
    },
    {
      min: 2,
      max: 16,
      message: "收货人姓名长度需要在2-16个汉字或字符之间，不能包含特殊字符",
      trigger: "blur",
    },
  ],
  mobile: [{ required: true, message: "手机号格式不正确" }],
  detail: [
    {
      required: true,
      message: "详细地址长度需要在5-120个汉字或字符，不能包含表情符号",
      trigger: "blur",
    },
    {
      min: 5,
      max: 96,
      message: "详细地址长度需要在5-96个汉字或字符，不能包含表情符号",
      trigger: "blur",
    },
  ],
};
const state = reactive({
  loading: true,
  list: [],
  addressData: [],
  addressCityData: null,
  addressCountyData: null,
  province_enabled: false,
  province: "",
  province_id: "",
  city_posi: "",
  city_id: "",
  region_posi: "",
  county_id: "",
  form: {
    user_id: store.state.user.userId,
    country_code: "86",
    address_id: "",
    province: "",
    province_id: "",
    city: "",
    city_id: "",
    county: "",
    area_id: "",
    detail: "",
    mobile: null,
    address_mobile: "",
    name: "",
    address_name: "",
    postal_code: "",
    street: "",
    is_default: 0,
  },
  user_id: store.state.user.userId,
  columnsStreet: [],
  page: 1,
  postCount: 0,
  nav: 100,
});
const save_btn_enabled = computed(() => {
  console.log("state.from.detail----", state.form.detail);
  if (
    !state.form.name ||
    state.form.name.length == 0 ||
    !state.form.mobile ||
    state.form.mobile.length == 0 ||
    !state.form.detail ||
    state.form.detail.length == 0
  ) {
    return false;
  } else if (state.province_enabled) {
    if (
      !state.form.province_id ||
      state.form.province_id.length == 0 ||
      !state.form.province ||
      state.form.province.length == 0 ||
      !state.form.city_id ||
      state.form.city_id.length == 0 ||
      !state.form.city ||
      state.form.city.length == 0
    ) {
      return false;
    }
  }
  return true;
});
onMounted(async () => {
  // 获取省列表
  let item = route.query.item ? JSON.parse(route.query.item) : null;

  if (item) {
    state.form.country_code = item.country_code;
  }
  await getAddressData();

  if (item) {
    state.form.address_id = item.id;

    if (Number(item.province_id) > 0) {
      let province_item = state.addressData.find(
        (pro_item) => Number(pro_item.id) === Number(item.province_id)
      );
      if (province_item) {
        //匹配到省数据-赋值+请求对应市列表
        state.form.province = item.province;
        state.form.province_id = Number(item.province_id);

        console.log("匹配到省数据-赋值+请求对应市列表");

        //赋值市列表
        state.addressCityData = province_item.list;
      } else {
        console.log("未匹配到省数据");

        // 清空省市区街道邮编
        state.form.province_id = "";
        state.form.province = "";
        state.form.city_id = "";
        state.form.city = "";
        state.form.area_id = "";
        state.form.county = "";
        state.form.street = "";
        state.form.postal_code = "";
      }
    }

    if (Number(item.city_id) > 0) {
      if (state.addressCityData) {
        let city_item = state.addressCityData.find(
          (c_item) => Number(c_item.id) === Number(item.city_id)
        );
        if (city_item) {
          //匹配到市数据-赋值+请求对应区列表
          state.form.city = item.city;
          state.form.city_id = Number(item.city_id);
          state.form.county = item.county;

          console.log("匹配到省数据-赋值+请求对应区列表");

          //赋值区列表
          await getCoutyData(item.city_id);
        }
      }
    }

    if (Number(item.area_id) > 0) {
      console.log("区列表---", state.addressCountyData);

      if (state.addressCountyData) {
        let couty_item = state.addressCountyData.find(
          (c_item) => Number(c_item.id) === Number(item.area_id)
        );
        if (couty_item) {
          //匹配到市数据-赋值+请求对应区列表
          state.form.county = item.county;
          state.form.area_id = Number(item.area_id);

          // 赋值街道列表
          await new_address_street(item.area_id);
        }
      }
    }

    if (Number(item.address_mobile) > 0) {
      state.form.mobile = Number(item.address_mobile);
      state.form.address_mobile = item.address_mobile;
    }

    state.form.detail = item.address;

    state.form.name = item.address_name;
    state.form.address_name = item.address_name;
    state.form.postal_code = Number(item.address_postal_code)
      ? Number(item.address_postal_code)
      : "";
    state.form.street = item.street;
    state.form.is_default = item.is_default ? 1 : 0;

    if (item.country_code) {
      countryCode.codeList.map((e) => {
        // eslint-disable-next-line eqeqeq
        if (e.id == item.country_code) {
          state.form.code_posi = `${e.name} +${e.id}`;
        }
      });
    }
  }
});
onBeforeUnmount(() => {});

const addAddress = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      const data = JSON.parse(JSON.stringify(state.form));
      state.columnsStreet.forEach((e) => {
        if (data.street == e.id) {
          data.street = e.name;
        }
      });

      let url = "/apis/address/naa";
      if (data.address_id) {
        url = "/apis/address/nua";
      }
      let updateAddress = null;
      updateAddress = await post(url, data);
      if (updateAddress) {
        if (Number(updateAddress.status) == 0) {
          ElMessage({
            showClose: true,
            message: updateAddress.message ? updateAddress.message : "操作成功",
            type: "success",
          });
          cancel();
        } else {
          ElMessage({
            showClose: true,
            message: updateAddress.message,
            type: "error",
          });
        }
      }
    } else {
      console.log("error submit!", fields);
    }
  });
};
function cancel() {
  router.push(`/address/address_list`);
}

const changeA = async (id, arr, str, isChange = false) => {
  //手动修改地址-删除不匹配地址标记

  // 切换国家编码
  if (str === "addressCountryCode") {
    state.form.country_code = id;

    // 切换国家-清空即有省/市/区/街道/地址/邮编数据
    state.form.province_id = "";
    state.form.province = "";
    state.form.city_id = "";
    state.form.city = "";
    state.form.area_id = "";
    state.form.county = "";
    state.form.street = "";
    state.form.postal_code = "";

    // 请求对应省市数据
    getAddressData();
  } else {
    arr.forEach((e) => {
      if (Number(e.id) === Number(id)) {
        if (str === "addressCityData") {
          state.form.province = e.name;

          if (isChange == true) {
            // 切换省-清空即有市/区/街道/地址数据
            state.form.city_id = "";
            state.form.city = "";
            state.form.area_id = "";
            state.form.county = "";
            state.columnsStreet = [];
            state.form.street = "";
            state.form.postal_code = "";
          }

          // 切换对应市可选list
          state[str] = e.list;
        } else if (str === "addressCountyData") {
          state.form.city = e.name;

          if (isChange == true) {
            // 切换市-清空即有区/街道/地址数据
            state.form.area_id = "";
            state.form.county = "";
            state.columnsStreet = [];
            state.form.street = "";
            state.form.postal_code = "";
          }

          // 请求对应区list
          getCoutyData(e.id);
        } else if (str == "addressStreetData") {
          state.form.county = e.name;

          if (isChange == true) {
            // 切换区-清空即有街道/地址数据+预填邮编
            state.columnsStreet = [];
            state.form.street = "";
            if (Number(e.postal_code) > 0) {
              state.form.postal_code = e.postal_code;
            }
          }

          new_address_street(e.id);
        } else if (str == "addressDetailData") {
          if (isChange == true) {
            // 切换街道-清空即有地址数据
          }
        }
      }
    });
  }
};

//根据国家编码-获取对应省市数据
const getAddressData = async () => {
  //中/港/澳/台/泰-默认支持省市区
  if (
    state.form.country_code == "66" ||
    state.form.country_code == "86" ||
    state.form.country_code == "852" ||
    state.form.country_code == "853" ||
    state.form.country_code == "886"
  ) {
    state.province_enabled = true;
  }

  state.columnsStreet = [];

  const addressData = await get("/apis/address/get_nad", {
    code: state.form.country_code,
  });
  if (addressData) {
    a(addressData.data);
    state.addressData = addressData.data;

    state.province_enabled =
      addressData.data && addressData.data.length ? true : false;
  }
  function a(e) {
    e.map((r) => {
      if (r.list && r.list.length) {
        a(r.list);
      } else {
        delete r.list;
      }
    });
  }
};

// 根据国家编码+市ID请求区list
const getCoutyData = async (city_id) => {
  const { data } = await get("/apis/address/get_als", {
    id: city_id,
    code: state.form.country_code,
  });

  state.addressCountyData = data;
};

// eslint-disable-next-line no-unused-vars
// 根据国家编码+区ID请求街道list
const new_address_street = async (area_id) => {
  const { data } = await get("/apis/address/get_address_street", {
    code: state.form.country_code,
    county_id: area_id,
  });
  state.columnsStreet = data.streets;
};
const countryCode = {
  codeList: [
    {
      id: "86",
      name: "中国大陆",
    },
    {
      id: "852",
      name: "中国香港",
    },
    {
      id: "853",
      name: "中国澳门",
    },
    {
      id: "886",
      name: "中国台湾",
    },
    {
      id: "61",
      name: "澳大利亚",
    },
    {
      id: "1",
      name: "加拿大",
    },
    {
      id: "33",
      name: "法国",
    },
    {
      id: "49",
      name: "德国",
    },
    {
      id: "62",
      name: "印度尼西亚",
    },
    {
      id: "39",
      name: "意大利",
    },
    {
      id: "81",
      name: "日本",
    },
    {
      id: "82",
      name: "韩国",
    },
    {
      id: "60",
      name: "马来西亚",
    },
    {
      id: "64",
      name: "新西兰",
    },
    {
      id: "31",
      name: "荷兰",
    },
    {
      id: "63",
      name: "菲律宾",
    },
    {
      id: "7",
      name: "俄罗斯",
    },
    {
      id: "65",
      name: "新加坡",
    },
    {
      id: "46",
      name: "瑞典",
    },
    {
      id: "66",
      name: "泰国",
    },
    {
      id: "44",
      name: "英国",
    },
    {
      id: "380",
      name: "乌克兰",
    },
    {
      id: "1",
      name: "美国",
    },
    {
      id: "84",
      name: "越南",
    },
  ],
  payList: [
    "+86  中国大陆",
    "+852 中国香港",
    "+853 中国澳门",
    "+886 中国台湾",
    "+61  澳大利亚",
    "+1   加拿大",
    "+33  法国",
    "+49  德国",
    "+62  印度尼西亚",
    "+39  意大利",
    "+81  日本",
    "+82  韩国",
    "+60  马来西亚",
    "+64  新西兰",
    "+31  荷兰",
    "+63  菲利宾",
    "+7   俄罗斯",
    "+65  新加坡",
    "+46  瑞典",
    "+66  泰国",
    "+44  英国",
    "+380 乌克兰",
    "+1   美国",
    "+84  越南",
  ],
  payCode: [
    86, 852, 853, 886, 61, 1, 33, 49, 62, 39, 81, 82, 60, 64, 31, 63, 7, 65, 46,
    66, 44, 380, 1, 84,
  ],
};
</script>
